import React, { useState, useRef, useEffect } from 'react';
import { InputGroup, InputGroupAddon, InputGroupText, Label } from 'reactstrap';
import * as FeatherIcon from 'react-feather';
import ReactDateRangePicker from 'react-bootstrap-daterangepicker';
import moment from 'moment';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { getRoom } from '../../../utils/variables';
import { handleDateRange } from '../FiltersLayout';
import { color } from '../../../utils/getColors';
import { RootState } from '../../../store';
import { saveSelectedFilters } from '../../../pages/Room/store';

export type DateRangeProps =
    { startDate: number, endDate: number }

export const DatePicker = () => {
    const room = getRoom();
    const dispatch = useDispatch();
    const { dateRange: selectedDate } = useSelector((state: RootState) => state.selectedFilters.filters);
    const isValidDateRange = (dateRange: any): dateRange is DateRangeProps =>
        dateRange && 'startDate' in dateRange && 'endDate' in dateRange;
    const finalDateRange: DateRangeProps = isValidDateRange(selectedDate) ? selectedDate : { startDate: 0, endDate: 0 };
    const finalRange = handleDateRange(finalDateRange, room.start_date);
    const [focus, setFocus] = useState(false);
    const dateRef = useRef<any>(null);

    useEffect(() => {
        if (finalRange && dateRef.current) {
            dateRef.current.setStartDate(moment.unix(finalRange.startDate).toDate());
            dateRef.current.setEndDate(moment.unix(finalRange.endDate).toDate());
        }
    }, [finalRange]);
    const convertedTime = moment(moment(room.start_date).toDate()).unix()
    || (finalRange ? finalRange.startDate : moment(moment().toDate()).unix());

    const inputRange = {
        'All time': [moment.unix(convertedTime).toDate(), moment().toDate()],
        'Last 24 Hours': [moment().subtract(24, 'hours').toDate(), moment().toDate()],
        'Last 7 Days': [moment().subtract(6, 'days').toDate(), moment().toDate()],
        'Last 30 Days': [moment().subtract(29, 'days').toDate(), moment().toDate()],
        'Last 3 Months': [
            moment().subtract(3, 'month').toDate(),
            moment().toDate()
        ],
    };

    const handleCallback = (start: moment.Moment, end: moment.Moment) => {
        const newDateRange = {
            startDate: moment(start).unix(),
            endDate: moment(end).unix(),
        };
        const notUpdated = _.isEqual({
            startDate: moment(moment(room.start_date).toDate()).unix(),
            endDate: moment(moment().endOf('day')).unix(),
        }, newDateRange);
        dispatch(saveSelectedFilters({
            dateRange: notUpdated ? { startDate: 0, endDate: 0 } : newDateRange
        }));
    };

    return (
        <button type="button"
            className="btn p-0 cursor-default"
            data-testid="date-range-picker-button"
        >
            <InputGroup border={focus ? 'active' : 'none'} className="d-inline-flex mt-0">
                <Label className="d-flex w-100">
                    <ReactDateRangePicker onCallback={handleCallback}
                        initialSettings={{
                            alwaysShowCalendars: true,
                            ranges: inputRange,
                            locale: { format: 'DD/MM/YYYY' },
                        }}
                        ref={(ref: any) => {
                            dateRef.current = ref;
                        }}
                    >
                        <input placeholder="dd/mm/yyyy"
                            type="text"
                            data-testid="input-range"
                            className="form-control w-65"
                            onFocus={() => setFocus(true)}
                            onBlur={() => setFocus(false)}
                        />
                    </ReactDateRangePicker>
                    <InputGroupAddon addonType="prepend"
                        className={`small-border cursor-pointer ${focus ? 'border-left' : 'border-left'}`}
                    >
                        <InputGroupText className="bg-white border-left-0"
                            onFocus={() => setFocus(true)}
                            onBlur={() => setFocus(false)}
                        >
                            <FeatherIcon.Calendar size={18} color={color.blue[500]} />
                        </InputGroupText>
                    </InputGroupAddon>
                </Label>
            </InputGroup>
        </button>
    );
};
