import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'reactstrap';
import { ArrowUp } from 'react-feather';
import { BreadCrumbs } from '../../../../components/BreadCrumbs';
import { BackButton } from '../../../../components/Atoms';
import { getRoom } from '../../../../utils/variables';
import { TimelineChart } from './TimelineChart';
import { color } from '../../../../utils/getColors';
import { GET_CLUSTERS, GET_SUB_CLUSTERS } from '../../../../graphql';
import { ContentView } from './ContentsView';
import { TabsView } from './TabsView';
import { EngagementAndSentiment } from './EngagementAndSentiment';
import { Loading } from '../../../../components/Loading';
import { OriginalPost } from './OriginalPoster';
import { TopActors } from './TopActors';
import { TopSources } from './TopSources';
import { TopKeywords } from './TopKeywords';
import { LocationMentions } from './LocationMentions';
import { SelectedItems } from './SelectedItems';
import { RootState } from '../../../../store';
import { setNarrativesComparisonList, setNarrativesThemeComparisonList } from '../../store';
import { Cluster, SubCluster } from '../types';
import { NarrativeThemes } from './NarrativeThemes';

type CollapsableState = {
    [key: string]: boolean;
};

type SelectedNarrativesType = {
    id: string
    title: string
}

export const Comparision = () => {
    const room = getRoom();
    const history = useHistory();
    const dispatch = useDispatch();
    const isParent = history.location.pathname.includes('narrative-theme');
    const { selectedNarrativeThemes } = useSelector((state: RootState) => state.narrativeThemeComparison);
    const { selectedNarratives } = useSelector((state: RootState) => state.narrativeComparison);
    const colors = [color.blue[500], color.graphColors.magenta, color.graphColors.robinsEggBlue, color.graphColors.tomato];
    const [collapsable, setCollapsable] = useState<CollapsableState>({});
    const handleCollapsable = (element: string) => {
        setCollapsable(prev => ({
            ...prev,
            [element]: !prev[element]
        }));
    };
    const { data: clustersData, loading: clustersLoading } = useQuery(GET_CLUSTERS, {
        variables: {
            projectId: room.project_id,
            id: isParent && selectedNarrativeThemes.map(({ id }) => id)
        },
        fetchPolicy: 'no-cache',
        skip: isParent && !selectedNarrativeThemes.length,
        onCompleted: () => {
            const title = selectedNarrativeThemes.filter((narrative: SelectedNarrativesType) => narrative.title);
            if (title.length < 1 && clustersData?.getClusters?.clusters) {
                const clusters = clustersData.getClusters.clusters as Cluster[];
                dispatch(setNarrativesThemeComparisonList({
                    selectedNarrativeThemes: clusters.map((cluster) => ({
                        id: cluster.id,
                        title: cluster.title
                    }))
                }));
            }
        }
    });
    const { data: subClustersData, loading: subClustersLoading } = useQuery(GET_SUB_CLUSTERS, {
        variables: {
            projectId: room.project_id,
            ids: !isParent && selectedNarratives.map(({ id }) => id)
        },
        fetchPolicy: 'no-cache',
        skip: !isParent && !selectedNarratives.length,
        onCompleted: () => {
            const title = selectedNarratives.filter((narrative: SelectedNarrativesType) => narrative.title);
            if (title.length < 1 && subClustersData?.getSubClusters) {
                const clusters = subClustersData.getSubClusters as SubCluster[];
                dispatch(setNarrativesComparisonList({
                    selectedNarratives: clusters.map((cluster) => ({
                        id: cluster.id,
                        title: cluster.subClusterTitle
                    }))
                }));
            }
        }
    });
    const bread = [
        { title: 'Situation Rooms', href: '/situation-rooms' },
        { title: `${room.project_name}`, href: `/situation-rooms/${room.id}` },
        { title: 'AI Narratives', href: `/situation-rooms/${room.id}/ai-narratives` },
        { title: `Narrative${isParent ? ' theme' : ''} comparison` }
    ];
    const clusterData = isParent ? clustersData?.getClusters.clusters || [] : subClustersData?.getSubClusters || [];
    // clusters data should be sorted in the same order as selectedNarrativeThemes or selectedNarratives
    const sortedClusterData = (clusterData as Cluster[] | SubCluster[]).sort((a, b) => {
        const data = isParent ? selectedNarrativeThemes : selectedNarratives;
        return data.findIndex(({ id }) => id === a.id) - data.findIndex(({ id }) => id === b.id);
    });
    const scrollToTop = () => {
        document.getElementsByClassName('content-page')[0]?.scrollTo({ top: 0, behavior: 'smooth' });
    };
    const shouldRenderScrollerButton = useScrollPosition({
        targetScroll: 300
    });

    if (clustersLoading || subClustersLoading) {
        return <Loading gif relative height={600} />;
    }

    return (
        <main>
            <div className="page-padding position-relative" id="selected-cluster-view">
                {shouldRenderScrollerButton ? (
                    <Button color="primary" className="scroll-up-button mw-0 p-2 shadow" onClick={scrollToTop}>
                        <ArrowUp />
                    </Button>
                ) : null}
                <BreadCrumbs items={bread} />
                <BackButton pathName={`/situation-rooms/${room.id}/ai-narratives`} gotToPreviousPath />
                <h3 className="pb-2 m-0">Narrative {isParent ? 'theme ' : ''}comparison</h3>
                <hr className="mb-4" />
                <SelectedItems clusterData={sortedClusterData} isParent={isParent} />
                {!isParent && (
                    <NarrativeThemes
                        isCollapsed={!collapsable.narrativeThemes}
                        setCollapsable={() => handleCollapsable('narrativeThemes')}
                        narratives={sortedClusterData as SubCluster[]}
                    />
                )}
                <TimelineChart isCollapsed={!collapsable.timeline}
                    setCollapsable={() => handleCollapsable('timeline')}
                    data={sortedClusterData}
                    isParent={isParent}
                    colors={colors}
                    loading={clustersLoading || subClustersLoading}
                />
                <EngagementAndSentiment
                    isCollapsed={!collapsable.engagement}
                    setCollapsable={() => handleCollapsable('engagement')}
                    clusterData={sortedClusterData}
                />
                {!isParent && (
                    <OriginalPost
                        isCollapsed={!collapsable.originalPost}
                        setCollapsable={() => handleCollapsable('originalPost')}
                        clusterData={sortedClusterData as SubCluster[]}
                    />
                )}
                <TopActors
                    isCollapsed={!collapsable.topActors}
                    setCollapsable={() => handleCollapsable('topActors')}
                    clusterData={sortedClusterData}
                />
                <TopSources
                    isCollapsed={!collapsable.topSources}
                    setCollapsable={() => handleCollapsable('topSources')}
                    clusterData={sortedClusterData}
                />
                <TopKeywords
                    isCollapsed={!collapsable.topKeywords}
                    setCollapsable={() => handleCollapsable('topKeywords')}
                    clusterData={sortedClusterData}
                />
                <LocationMentions
                    isCollapsed={!collapsable.locationMentions}
                    setCollapsable={() => handleCollapsable('locationMentions')}
                    clusterData={sortedClusterData}
                />
                {isParent && (
                    <TabsView isCollapsed={!collapsable.narrative}
                        setCollapsable={() => handleCollapsable('narrative')}
                        data={sortedClusterData as Cluster[]}
                        isParent={isParent}
                        loading={clustersLoading || subClustersLoading}
                    />
                )}
                <ContentView isCollapsed={!collapsable.content}
                    setCollapsable={() => handleCollapsable('content')}
                    data={sortedClusterData}
                    loading={clustersLoading || subClustersLoading}
                    isParent={isParent}
                    ids={(isParent ? selectedNarrativeThemes : selectedNarratives).map(({ id }) => id)}
                />
            </div>
        </main>
    );
};

type UseScrollPositionProps = {
    targetScroll: number
}

export const useScrollPosition = ({ targetScroll }: UseScrollPositionProps) => {
    const [hasPassedTargetScroll, setHasPassedTargetScroll] = useState(false);
    useEffect(() => {
        const container = document.getElementsByClassName('content-page')[0] || window;
        const updatePosition = () => {
            const isPastTargetScroll = container.scrollTop > targetScroll;
            if (isPastTargetScroll !== hasPassedTargetScroll) {
                setHasPassedTargetScroll(isPastTargetScroll);
            }
        };
        container.addEventListener('scroll', updatePosition);
        return () => container.removeEventListener('scroll', updatePosition);
    }, [targetScroll, hasPassedTargetScroll]);

    return hasPassedTargetScroll;
};
