import React from 'react';
import { Star } from 'react-feather';
import { Button, Spinner } from 'reactstrap';
import { useSelector } from 'react-redux';
import { getRoom } from '../../../../utils/variables';
import { StickToBottom } from '../../../../components/StickToBottom';
import { RemoveClusterButton } from './RemoveClusterButton';
import { SelectedClusters } from './ClustersTable';
import { useUpdateClustersWatchlist } from '../../../../services/Clusters/watchlist';
import { RootState } from '../../../../store';
import { getShowAiNarrativeCompare } from '../Utils/GetShowAiNarrativeCompare';
import { CompareButtons, CompareButtonType } from '../Utils/CompareButtons';

type ClustersTableActionsBarProps = {
    selectedClusters: SelectedClusters[]
    clearSelection: () => void
    isParent?: boolean
}

export const ClustersTableActionsBar = ({
    selectedClusters,
    isParent,
    clearSelection
}: ClustersTableActionsBarProps) => {
    const room = getRoom();
    const contentActionsEnabled = room.instance?.plan?.others?.contentActions;
    const optionsListToAdd = selectedClusters.filter(cluster => !cluster.isWatching);
    const optionsListToRemove = selectedClusters.filter(cluster => cluster.isWatching);
    const { selectedNarrativeThemes } = useSelector((state: RootState) => state.narrativeThemeComparison);

    const { updateClustersWatchlist: addToWatchlist, loading: addingToWatchlist } = useUpdateClustersWatchlist({
        clusterIds: optionsListToAdd.map(cluster => cluster.id),
        isWatching: true,
        type: 'cluster'
    });
    const { updateClustersWatchlist: removeFromWatchlist, loading: removingFromWatchlist } = useUpdateClustersWatchlist({
        clusterIds: optionsListToRemove.map(cluster => cluster.id),
        isWatching: false,
        type: 'cluster'
    });

    const handleAddToWatchlist = (e: React.MouseEvent<any>) => {
        e.stopPropagation();
        e.preventDefault();
        if (addingToWatchlist) return;
        return addToWatchlist();
    };
    const handleRemoveFromWatchlist = (e: React.MouseEvent<any>) => {
        e.stopPropagation();
        e.preventDefault();
        if (removingFromWatchlist) return;
        return removeFromWatchlist();
    };

    if (!selectedClusters?.length || !contentActionsEnabled) return null;

    const showCompare = getShowAiNarrativeCompare({
        selectedInComparison: selectedNarrativeThemes,
        selectedItems: selectedClusters,
    });

    return (
        <StickToBottom>
            <div className="bg-hover px-2 py-1 d-flex align-items-center justify-content-between" data-testid="clusters-actions-bar">
                <p className="m-0 font-weight-bold">
                    {selectedClusters.length} item
                    {selectedClusters.length !== 1 ? 's' : ''} selected
                </p>
                <div className="d-flex align-items-end gap-2">
                    {showCompare && (
                        <CompareButtons clusterIds={selectedClusters.map(item => item.id)}
                            clusterTitles={selectedClusters.map(item => item.title) as string[]}
                            type={CompareButtonType.AddButton}
                            isParent={isParent}
                            onAddedToComparison={clearSelection}
                        />
                    )}
                    {optionsListToAdd.length > 0 && (
                        <Button onClick={handleAddToWatchlist} data-testid="watchlist-button">
                            {addingToWatchlist ? (
                                <Spinner color="primary" type="grow" size="sm" data-testid="spinner" />
                            ) : (
                                <Star size={18} className="mb-11" />
                            )}
                            <span className="pl-1">Add to watchlist</span>
                        </Button>
                    )}
                    {optionsListToRemove.length > 0 && (
                        <Button onClick={handleRemoveFromWatchlist} data-testid="watchlist-button">
                            {removingFromWatchlist ? (
                                <Spinner color="primary" type="grow" size="sm" data-testid="spinner" />
                            ) : (
                                <Star size={18} className="mb-11 fill-primary" />
                            )}
                            <span className="pl-1">Remove from watchlist</span>
                        </Button>
                    )}
                    <RemoveClusterButton selectedClusters={selectedClusters} onCompleted={clearSelection} />
                </div>
            </div>
        </StickToBottom>
    );
};
