import React from 'react';
import { ChevronDown, ChevronRight } from 'react-feather';
import { useQuery } from '@apollo/client';
import { Link } from 'react-router-dom';
import { SubCluster, Cluster } from '../types';
import { IconButton } from '../../../../components/Atoms';
import { WidgetName, WidgetTextTooltip } from '../SelectedClusterView/Summary/WidgetTextTooltip';
import { GET_CLUSTER_TITLES } from '../../../../graphql';
import { getRoom } from '../../../../utils/variables';
import { truncateString } from '../../../../utils/text';

type NarrativeThemesProps = {
    isCollapsed: boolean;
    setCollapsable: () => void;
    narratives: SubCluster[];
};

export const NarrativeThemes = ({ isCollapsed, setCollapsable, narratives }: NarrativeThemesProps) => {
    const narrativeThemeIds = narratives.map((c) => c.topClustersMappedId);
    const room = getRoom();
    const { data } = useQuery(GET_CLUSTER_TITLES, {
        variables: {
            projectId: room.project_id,
            id: narrativeThemeIds
        },
        skip: !narrativeThemeIds.length
    });

    const narrativeThemes = data?.getClusters?.clusters as Cluster[] || [];

    if (!narrativeThemes.length) return null;
    return (
        <>
            <div className="d-flex align-items-center gap-1 cursor-pointer" onClick={setCollapsable}>
                <IconButton
                    className="icon-button text-decoration-none"
                    icon={isCollapsed ? <ChevronDown /> : <ChevronRight />}
                    text={<WidgetTextTooltip widgetName={WidgetName.NarrativeTheme} noMargin className="ml-2" />}
                />
            </div>
            <div className="grid_container_narrative mb-4 mt-3">
                {isCollapsed && [0, 1, 2, 3].map((_, index) => {
                    const currentNarrative = narratives[index];
                    if (!currentNarrative) {
                        return <div className="border p-2" key={_} />;
                    }
                    const narrativeTheme = narrativeThemes.find((c) => c.id === currentNarrative.topClustersMappedId);
                    if (!narrativeTheme) {
                        return <div className="border p-2" key={_} />;
                    }
                    return (
                        <div className="bg-white p-2 border" key={narrativeTheme.id}>
                            <p className="mb-2">{truncateString(narrativeTheme.title + narrativeTheme.title, 160, false)}</p>
                            <Link className="btn-link"
                                to={`/situation-rooms/${room.id}/ai-narratives/narrative-theme/${narrativeTheme.id}`}
                            >
                                View summary
                            </Link>
                        </div>
                    );
                })}
            </div>
        </>
    );
};
