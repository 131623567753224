import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { BackButton } from '../../../../components/Atoms';
import { BreadCrumbs } from '../../../../components/BreadCrumbs';
import { getRoom } from '../../../../utils/variables';
import { TabsContainer } from '../SelectedClusterView/TabsContainer';
import { ExtractCluster, SubCluster } from '../types';
import { Loading } from '../../../../components/Loading';
import { ActionButtons } from '../SelectedClusterView/ActionButtons';
import { SubClusterWrapper } from '..';
import { NarrativeThemeComparison } from '../Utils/ComparisonContainer/NarrativeThemeComparison';
import { NarrativeComparison } from '../Utils/ComparisonContainer/NarrativeComparison';
import { RootState } from '../../../../store';
import { TranslateButton } from '../../../../components/Atoms/TranslateButton';
import { useTranslateMultipleText } from '../../../../services/google/translate';
import { SelectedFilters } from '../../../../components/NewFilters/components/SelectedFilters';

export const NarrativeDetails = () => {
    const room = getRoom();
    const match = useRouteMatch();
    const { selectedNarrativeThemes } = useSelector((state: RootState) => state.narrativeThemeComparison);
    const { selectedNarratives } = useSelector((state: RootState) => state.narrativeComparison);
    const allFilters = useSelector((state: any) => state.clusterFilters.filters);

    const narrativeThemeId = (match.params as { id: string }).id;

    const bread = [
        { title: 'Situation Rooms', href: '/situation-rooms' },
        { title: `${room.project_name}`, href: `/situation-rooms/${room.id}` },
        { title: 'AI Narratives', href: `/situation-rooms/${room.id}/ai-narratives` },
        { title: 'Narrative details' }
    ];

    const isFilterSelected = () => {
        const dateCheck = _.isEqual({
            startDate: 0,
            endDate: 0,
        }, allFilters.dateRange);
        let noneSelected = true;

        const finalFilters = { ...allFilters,
            threatTypes: [],
            ...({ mentions: [], hashtags: [], entities: [] }) };
        Object.entries(finalFilters).forEach(([key, value]) => {
            if (Array.isArray(value)) {
                if (value.length > 0) { noneSelected = false; }
            }
        });
        return (!noneSelected || (!dateCheck));
    };

    return (
        <>
            <div className={`position-sticky top-0 bg-white z-index-10 ${
                (selectedNarrativeThemes.length || selectedNarratives.length) ? 'mb-n4' : ''}`}
            >
                {
                    selectedNarrativeThemes.length > 0 ? <NarrativeThemeComparison /> : null
                }
                {
                    selectedNarratives.length > 0 ? <NarrativeComparison /> : null
                }
            </div>
            <div className="page-padding">
                <BreadCrumbs items={bread} />
                <BackButton pathName={`/situation-rooms/${room.id}/ai-narratives`} gotToPreviousPath />
                <h2 className="pb-4 m-0">Narrative details</h2>
                {isFilterSelected() && (
                    <div className="bg-white mb-3 p-3">
                        <SelectedFilters
                            setFilterName={() => null}
                            start_date={room?.start_date}
                            display
                            contentIds={[]}
                            watchlist={false}
                            clusters
                        />
                    </div>

                )}
                <SubClusterWrapper id={narrativeThemeId}
                    renderComponent={(cluster) => (
                        <Narrative cluster={cluster} />
                    )}
                    renderLoading={() => <Loading gif relative height={400} />}
                />
            </div>
        </>
    );
};

type NarrativeProps = {
    cluster: SubCluster;
}

const Narrative = ({ cluster }: NarrativeProps) => {
    const { getTranslation, data: translated, loading: translateLoading } = useTranslateMultipleText({
        text: cluster.subClusterTitle,
        extracts: [cluster.subClusterTitle, cluster.subClusterSummary]
    });
    const isNoneEnglish = /[^a-zA-Z0-9\s.,!?'";:()-]/.test(cluster.subClusterTitle);
    const translatedTitle = translated[0].translations[0];
    const translatedSummary = translated[1].translations[0];
    return (
        <div id="selected-cluster-view" className="bg-white">
            <div className="d-flex align-items-center p-3">
                <p className="font-weight-bold m-0 w-75 flex-1">{translatedTitle}</p>
                {isNoneEnglish && (
                    <TranslateButton className="mr-2"
                        onClick={(e) => {
                            e.preventDefault();
                            getTranslation();
                        }}
                        loading={translateLoading}
                    />
                )}
                <ActionButtons isSubCluster
                    clusterTitle={translatedTitle}
                    summary={translatedSummary}
                    isWatching={cluster?.isWatching}
                    cluster={cluster}
                />
            </div>
            <hr className="m-0" />
            <TabsContainer isParent={false}
                cluster={{
                    ...cluster,
                    subClusterTitle: translatedTitle,
                    subClusterSummary: translatedSummary
                } as ExtractCluster<typeof cluster>}
            />
        </div>
    );
};
