import React, { useState } from 'react';
import { Bookmark, ChevronDown, ChevronRight } from 'react-feather';
import {
    Button,
    Nav,
    NavItem,
    TabContent,
    Table,
    TabPane
} from 'reactstrap';
import { Link } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { Cluster, SubCluster } from '../types';
import { IconButton } from '../../../../components/Atoms';
import { SentimentCard } from '../SelectedClusterView/Summary/ClusterStats/SentimentCard';
import { Duplicates } from '../SelectedClusterView/Summary/ClusterStats/Duplicates';
import { Content } from '../SelectedClusterView/Summary/ClusterStats/Content';
import { Engagement } from '../SelectedClusterView/Summary/ClusterStats/Engagement';
import { ActionDropdown } from '../Utils/ActionDropdown';
import { TooltipWrapper } from '../../../../components/ToolTip/TooltipWrapper';
import { calculateAverageSentiment } from '../../../../utils/calculateAverageSentiment';
import { useUpdateClustersWatchlist } from '../../../../services/Clusters/watchlist';
import { GET_SUB_CLUSTERS_FOR_LIST } from '../../../../graphql';
import { getRoom } from '../../../../utils/variables';
import { OriginalPoster } from '../SelectedClusterView/Summary/ClusterStats/OriginalPoster';
import { LoadingState } from '../../Overview/Visualisation/Table/Loading';
import { LoadingState as TotalMatchesLoading } from '../../Overview/Visualisation/TotalMatchesAndThreatsOverTime/Loading';
import { WidgetName, WidgetTextTooltip } from '../SelectedClusterView/Summary/WidgetTextTooltip';
import { Pagination } from '../../../../components/Molecules';

type TabsViewProps = {
    isCollapsed: boolean;
    setCollapsable: () => void;
    data: Cluster[];
    isParent: boolean;
    loading: boolean;
};

export const TabsView = ({ isCollapsed, setCollapsable, data, isParent, loading }: TabsViewProps) => {
    const room = getRoom();
    const [activeTab, setActiveTab] = useState(1);
    const [offset, onOffsetChange] = useState(0);

    const { data: subClusterData, loading: subClustersLoading } = useQuery(GET_SUB_CLUSTERS_FOR_LIST, {
        variables: {
            projectId: room.project_id,
            ids: data[activeTab - 1]?.subClustersMappedId,
            skip: offset,
            limit: 10,
            sort: { field: 'volume', order: 'desc' }
        },
        skip: !(data[activeTab - 1]?.subClustersMappedId.length),
    });
    const handleTabSwitch = (index: number) => {
        onOffsetChange(0);
        setActiveTab(index);
    };

    return (
        <div className="mt-3">
            <IconButton
                className="icon-button text-decoration-none"
                icon={isCollapsed ? <ChevronDown /> : <ChevronRight />}
                onClick={setCollapsable}
                text={<WidgetTextTooltip widgetName={WidgetName.Narratives} noMargin className="ml-2" />}
                dataTestid="narratives"
            />
            <br />
            {isCollapsed && (
                <div>
                    {loading ? <TotalMatchesLoading items={[1]} />
                        : (
                            <div className="border rounded">
                                <Nav tabs className="grid_container_narrative">
                                    {data.map((item, index) => (
                                        <NavItem key={item.id}
                                            onClick={() => handleTabSwitch(index + 1)}
                                            className={`text-primary p-2 ${index !== 3 ? 'border-right' : ''} ${
                                                activeTab === index + 1 ? 'bg-white' : 'border-bottom'}`}
                                        >
                                            <Button color="link" className="p-0 mw-0">
                                                {item?.subClustersMappedId.length} Narrative
                                            </Button>
                                        </NavItem>
                                    )) }
                                </Nav>
                                <TabContent className="bg-white" activeTab={activeTab}>
                                    {data?.map((item1, index1) => (
                                        <TabPane key={item1.id} tabId={index1 + 1} className="p-4">
                                            {subClustersLoading ? (
                                                <div>
                                                    <LoadingState size={item1?.subClustersMappedId.length} />
                                                </div>
                                            ) : (
                                                <div className="border rounded overflow-hidden">
                                                    <Table className="m-0" hover>
                                                        <tbody>
                                                            {subClusterData?.getSubClusters?.map((item: SubCluster, index: number) => (
                                                                <tr key={item.id} className={index !== 0 ? 'border-top' : ''}>
                                                                    <SubclusterItem item={item} isParent={isParent} />
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </Table>
                                                    <Pagination className="p-2 border-top mb-0"
                                                        total={item1?.subClustersMappedId.length}
                                                        itemsPerPage={10}
                                                        offset={offset}
                                                        setOffset={onOffsetChange}
                                                    />
                                                </div>
                                            )}
                                        </TabPane>
                                    ))}
                                </TabContent>
                            </div>
                        )}
                </div>

            )}
        </div>
    );
};

type SubclusterTabPaneProps = {
    item: SubCluster;
    isParent: boolean;
}

const SubclusterItem = ({ item, isParent }: SubclusterTabPaneProps) => {
    const room = getRoom();
    const clusterLink = `/situation-rooms/${room.id}/ai-narratives/narrative/${item.id}`;
    return (
        <div id={`sub-cluster-view-${item.id}`} className="p-2">
            <div className="d-flex align-items-top justify-content-between pb-2">
                <p className="m-0 highlight-em mw-750 btn btn-link d-inline p-0 text-left">
                    <Link to={`${clusterLink}?tab=summary`} target="_blank">{item.subClusterTitle}</Link>
                </p>
                <div className="d-flex justify-content-end align-top content-top-bar">
                    <TooltipWrapper
                        tooltipText={item.isWatching
                            ? 'Remove from watchlist' : 'Add to watchlist'}
                        id={`sub-cluster-view-${item.id}_watchlist`}
                        placement="top-start"
                        container={`sub-cluster-view-${item.id}`}
                    >
                        <Button color="link" className="p-0 mw-0">
                            <BookmarkApi id={item.id}
                                isWatching={item.isWatching}
                            />
                        </Button>
                    </TooltipWrapper>
                    <ActionDropdown
                        isSubCluster={!isParent}
                        id={item.id}
                        defaultTitle={item.subClusterTitle}
                        defaultSummary={item.subClusterSummary}
                        containerId={`sub-cluster-view-${item.id}`}
                    />
                </div>
            </div>
            <div className="cluster-stats-align m-0">
                <Engagement count={item.engagement} disabled />
                <Link to={`${clusterLink}?tab=content`} target="_blank">
                    <Content count={item.volume}
                        subClusterId={item.id}
                        onClick={() => {
                            // empty
                        }}
                    />
                </Link>
                <Link to={`${clusterLink}?tab=content&hasDuplicates=true`} target="_blank">
                    <Duplicates
                        count={item.duplicatedDocsCount}
                        onClick={() => {
                            // empty
                        }}
                    />
                </Link>
                <SentimentCard
                    id={`sentiment_${item.id}`}
                    sentiments={item.sentimentJson}
                    averageSentiment={calculateAverageSentiment(item.sentimentJson)}
                />
                <OriginalPoster opCtype={item.originatorPostCtype}
                    opName={item.originatorPostName}
                    opId={item.originatorPostId}
                />
            </div>
        </div>
    );
};

const BookmarkApi = ({ id, isWatching }: {id: string, isWatching: boolean }) => {
    const [watching, setWatching] = useState(isWatching);
    const { updateClustersWatchlist } = useUpdateClustersWatchlist({
        clusterIds: [id],
        isWatching: !isWatching,
        type: 'subcluster'
    });
    return (
        <Bookmark size={20}
            color="#006FF9"
            fill={watching ? '#006FF9' : 'white'}
            aria-label={watching ? 'Remove from watchlist' : 'Add to watchlist'}
            data-testid="watchlist-star"
            onClick={() => {
                setWatching(!watching);
                updateClustersWatchlist();
            }}
        />
    );
};
