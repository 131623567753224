import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { Button, Input } from 'reactstrap';
import { EDIT_CLUSTER_SUBCLUSTER } from '../../../../../graphql';
import { getRoom } from '../../../../../utils/variables';
import { useModal } from '../../../../../context/modal/ModalComponent';
import { getToast } from '../../../../../utils/getToast';

type EditModalProps={
    id: string
    isParentCluster: boolean
    defaultTitle?: string
    defaultSummary?: string
}

export const EditModal = ({ id, isParentCluster, defaultTitle, defaultSummary }: EditModalProps) => {
    const room = getRoom();
    const toast = getToast();
    const { closeModal } = useModal();
    const [newTitle, setNewTitle] = useState(defaultTitle);
    const [newDescription, setNewDescription] = useState(defaultSummary);

    const [editCluster, { loading }] = useMutation(EDIT_CLUSTER_SUBCLUSTER, {
        onCompleted: () => {
            closeModal();
            toast.success(`${isParentCluster ? 'Narrative theme' : 'Narrative'} updated successfully `);
        },
        onError: () => {
            closeModal();
            toast.error(`Failed to update ${isParentCluster ? 'narrative theme' : 'narrative'}`);
        },
        update: (cache) => {
            cache.modify({
                id: cache.identify({ __typename: isParentCluster ? 'Cluster' : 'SubCluster', id }),
                fields: {
                    [isParentCluster ? 'title' : 'subClusterTitle']: () => newTitle as string,
                    [isParentCluster ? 'description' : 'subClusterSummary']: () => newDescription as string
                }
            });
        },
        fetchPolicy: 'no-cache',
        awaitRefetchQueries: true
    });
    const onEditClusterDetails = () => editCluster({
        variables: {
            projectId: room.project_id,
            clusterId: isParentCluster ? id : null,
            subClusterId: !isParentCluster ? id : null,
            data: {
                title: newTitle,
                description: newDescription
            }
        }
    });

    return (
        <>
            <h3>Edit title and summary</h3>
            <hr />
            <p>{isParentCluster ? 'Narrative theme' : 'Narrative'} title</p>
            <Input type="text" onChange={(e) => setNewTitle(e.target.value)} value={newTitle} />
            <p className="mt-2">Summary</p>
            <Input type="textarea" onChange={(e) => setNewDescription(e.target.value)} value={newDescription} />
            <hr />
            <Button color="secondary" className="mr-2" onClick={closeModal}>Cancel</Button>
            <Button disabled={loading || (!newTitle?.trim() || !newDescription?.trim())}
                color="primary"
                onClick={onEditClusterDetails}
            >
                Save
            </Button>
        </>
    );
};
