import React from 'react';
import { useMutation } from '@apollo/client';
import { Button } from 'reactstrap';
import { REMOVE_SUB_CLUSTERS } from '../../../../../graphql';
import { getRoom } from '../../../../../utils/variables';
import { useModal } from '../../../../../context/modal/ModalComponent';
import { getToast } from '../../../../../utils/getToast';

type RemoveSubClustersConfirmationProps = {
    subClusterIds?: string[]
    onCompleted: () => void
}

export const RemoveSubClustersConfirmation = ({ subClusterIds, onCompleted }: RemoveSubClustersConfirmationProps) => {
    const { closeModal } = useModal();
    const room = getRoom();
    const toast = getToast();
    const [removeSubClusters, { loading }] = useMutation(REMOVE_SUB_CLUSTERS, {
        refetchQueries: 'active',
        onCompleted: () => {
            closeModal();
            onCompleted();
            toast.success('Narratives removed successfully');
        },
        onError: () => {
            closeModal();
            toast.error('Failed to remove narratives');
        }
    });
    const handleRemoveSubClusters = () => removeSubClusters({
        variables: {
            projectId: room.project_id,
            subClusterIds
        }
    });
    return (
        <>
            <h3>Are you sure that you want to remove these narratives?</h3>
            <hr />
            <p>The selected narratives will be fully removed from this research environment.</p>
            <hr />
            <Button color="secondary" className="mr-2" onClick={closeModal}>Cancel</Button>
            <Button disabled={loading} color="danger" onClick={handleRemoveSubClusters}>Remove</Button>
        </>
    );
};
