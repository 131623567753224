import React from 'react';
import { useHistory } from 'react-router-dom';
import { truncateString } from '../../../../../../utils/text';
import { SourceIconLabel } from '../../../../../../components/SourceIconLabel';
import { getRoom } from '../../../../../../utils/variables';
import { WidgetName, WidgetTextTooltip } from '../WidgetTextTooltip';
import { unsetParam } from '../../../../../../utils/urlParams';

type OriginalPosterProps = {
    setTab?: (a: string) => void
    disabled?: boolean
    opName?: string
    opCtype?: string
    opId?: string
}

export const OriginalPoster = ({
    setTab,
    disabled,
    opName,
    opCtype,
    opId
}: OriginalPosterProps) => {
    const history = useHistory();
    const room = getRoom();
    const setStoreValues = () => {
        if (disabled) return;
        if (opId) {
            return history.push(`/situation-rooms/${room.id}/matches/${opId}`);
        }
        if (setTab) setTab('original-poster');
        unsetParam('hasDuplicates');
    };
    return (
        <div className="cluster-stat-container" onClick={() => setStoreValues()}>
            <WidgetTextTooltip widgetName={WidgetName.OriginalPoster} clusterId={opId} />
            <div className={`${disabled ? 'text-black' : 'text-primary'}`}>
                <SourceIconLabel data={{ contentType: opCtype }} className="d-inline" />
                <span className="ctype btn btn-link d-inline p-0 mw-0">
                    {truncateString(opName, 20, false)}
                </span>
            </div>
        </div>
    );
};
