import React, { useState } from 'react';

type ReadMoreProps = {
    text: string,
    limit: number,
    className?: string
}
export const ReadMore = ({ text, limit, className }: ReadMoreProps) => {
    const [readMore, setReadMore] = useState(true);

    const toggleReadMore = () => {
        setReadMore(!readMore);
    };

    const textSpan = (
        <span onClick={toggleReadMore}
            className="readMore-button btn btn-link p-0 mw-0 ml-0"
        >
            {readMore ? 'Read more' : ' Read less'}
        </span>
    );

    return (
        <p data-testid={readMore ? 'read-more-closed' : 'read-more-open'} className={className || ''}>
            {readMore ? `${text.slice(0, limit)}...` : text} {textSpan}
        </p>
    );
};
