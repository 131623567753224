import React from 'react';
import { getSentimentIcon } from '../../../../components/Organisms/ContentTableView/SentimentCard';
import { capitalizeFirstLetter } from '../../../../utils/text';
import { TooltipItem } from '../../../../components/ToolTip';

export const SentimentPanel = ({ sentiment }) => (
    <div className="bg-white mb-3 p-2 rounded-sm">
        <div className="d-flex align-items-center">
            <h3 className="m-0">Sentiment</h3>
            <TooltipItem id="sentimentTooltip"
                content="Sentiment is how you platform perceives this content to be forwards the subject that it’s discussing"
                placement="left"
            />
        </div>
        {(sentiment !== 'positive' && sentiment !== 'neutral' && sentiment !== 'negative') ? (
            <div className="bg-verylight rounded p-2 mt-1">
                {getSentimentIcon(sentiment)}
                <strong>Unknown</strong>
            </div>
        ) : (
            <div className="bg-verylight rounded p-2 mt-1">
                {getSentimentIcon(sentiment)}
                <strong>{capitalizeFirstLetter(sentiment)}</strong>
            </div>
        )}
    </div>
);
