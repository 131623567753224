import { gql, useMutation } from '@apollo/client';
import { getRoom } from '../../utils/variables';
import { useGetActiveQueries } from '../getActiveQueries';

type ClusterWatchlistType = 'cluster' | 'subcluster';

type UseUpdateClustersWatchlistArgs = {
    clusterIds: string[]
    isWatching: boolean
    type: ClusterWatchlistType
}

export const useUpdateClustersWatchlist = ({ clusterIds, isWatching, type }: UseUpdateClustersWatchlistArgs) => {
    const room = getRoom();
    const refetchQueries = useGetActiveQueries(['getClusters', 'getSubClusters', 'getClustersForListView', 'getAllSubClusters']);
    const [updateClustersWatchlist, { loading }] = useMutation(UPDATE_CLUSTERS_WATCHLIST, {
        variables: {
            projectId: room.project_id,
            clusterIds,
            isWatching,
            type
        },
        update: (cache) => {
            for (const id of clusterIds) {
                cache.modify({
                    id: cache.identify({ __typename: type === 'cluster' ? 'Cluster' : 'SubCluster', id }),
                    fields: {
                        isWatching: () => isWatching
                    }
                });
            }
        },
        refetchQueries
    });

    return {
        updateClustersWatchlist,
        loading
    };
};

export const UPDATE_CLUSTERS_WATCHLIST = gql`
    mutation updateClustersWatchlist($projectId: String!, $clusterIds: [String]!, $isWatching: Boolean!, $type: ClusterWatchlistType!) {
        updateClustersWatchlist(projectId: $projectId, clusterIds: $clusterIds, isWatching: $isWatching, type: $type)
    }
`;
