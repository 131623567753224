import React from 'react';
import { useMutation } from '@apollo/client';
import { Button } from 'reactstrap';
import { REMOVE_CLUSTERS } from '../../../../../graphql';
import { getRoom } from '../../../../../utils/variables';
import { useModal } from '../../../../../context/modal/ModalComponent';
import { getToast } from '../../../../../utils/getToast';

type RemoveClustersConfirmationProps = {
    clusterIds?: string[]
    onCompleted: () => void
}

export const RemoveClustersConfirmation = ({ clusterIds, onCompleted }: RemoveClustersConfirmationProps) => {
    const { closeModal } = useModal();
    const room = getRoom();
    const toast = getToast();
    const [removeClusters, { loading }] = useMutation(REMOVE_CLUSTERS, {
        refetchQueries: 'active',
        onCompleted: () => {
            closeModal();
            onCompleted();
            toast.success('Narrative themes removed successfully');
        },
        onError: () => {
            closeModal();
            toast.error('Failed to remove narrative themes');
        }
    });
    const handleRemoveClusters = () => removeClusters({
        variables: {
            projectId: room.project_id,
            clusterIds
        }
    });
    return (
        <>
            <h3>Are you sure that you want to remove these narrative themes?</h3>
            <hr />
            <p>The selected narrative themes will be fully removed from this research environment.</p>
            <hr />
            <Button color="secondary" className="mr-2" onClick={closeModal}>Cancel</Button>
            <Button disabled={loading} color="danger" onClick={handleRemoveClusters}>Remove</Button>
        </>
    );
};
