import { useQuery } from '@apollo/client';
import React, { useState } from 'react';
import { X } from 'react-feather';
import { Button } from 'reactstrap';
import { GET_CLUSTERS_FOR_LIST_VIEW } from '../../../../../graphql';
import { Loading } from '../../../../../components/Loading';
import { ClustersTable } from '../../ListView/ClustersTable';
import { Cluster } from '../../types';
import { setParam, unsetParam } from '../../../../../utils/urlParams';
import { getRoom } from '../../../../../utils/variables';
import { useModal } from '../../../../../context/modal/ModalComponent';

export const AddNarrativeThemeModal = () => {
    const room = getRoom();
    const { closeModal } = useModal();
    const [clustersOffset, setClustersOffset] = useState(0);
    const { data: clustersData, loading: clusterLoading } = useQuery(GET_CLUSTERS_FOR_LIST_VIEW, {
        variables: {
            limit: 10,
            skip: clustersOffset,
            projectId: room.project_id,
            sort: { field: 'volume', order: 'desc' }
        },
        fetchPolicy: 'cache-and-network'
    });
    const getClustersData = clustersData?.getClusters;
    const clusters: Cluster[] = getClustersData?.clusters || [];
    const setSelectedCluster = (id: string) => {
        unsetParam('tab');
        if (!id) {
            return unsetParam('narrative-theme');
        }
        setParam('narrative-theme', id);
    };
    if (clusterLoading) {
        return <Loading gif relative height={400} />;
    }
    return (
        <>
            <h2>
                Add narrative for comparison
                <X className="float-right" type="button" onClick={closeModal} />
            </h2>
            <hr />
            <ClustersTable clusters={clusters}
                total={getClustersData?.total}
                offset={clustersOffset}
                onSetSelectedCluster={setSelectedCluster}
                onOffsetChange={setClustersOffset}
                itemsPerPage={10}
                isCompareModal
            />
            <Button color="secondary" className="mt-2" onClick={closeModal}>Close</Button>
        </>
    );
};
