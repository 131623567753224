import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Star } from 'react-feather';
import { Button, Col, CustomInput } from 'reactstrap';
import { Link } from 'react-router-dom';
import { saveSelectedCluster } from '../../store';
import { Cluster } from '../types';
import { useUpdateClustersWatchlist } from '../../../../services/Clusters/watchlist';
import { addHighlights, useQueryKeywords } from '../../../../services/Content/getQueryExtracts';
import { getHighlightedContent } from '../../../../utils/getHighlightedContent';
import { ActionDropdown } from '../Utils/ActionDropdown';
import { truncateString } from '../../../../utils/text';
import { Engagement } from '../SelectedClusterView/Summary/ClusterStats/Engagement';
import { Content } from '../SelectedClusterView/Summary/ClusterStats/Content';
import { Duplicates } from '../SelectedClusterView/Summary/ClusterStats/Duplicates';
import { SentimentCard } from '../SelectedClusterView/Summary/ClusterStats/SentimentCard';
import { SelectedClusters } from './ClustersTable';
import { calculateAverageSentiment } from '../../../../utils/calculateAverageSentiment';
import { SubClusters } from '../SelectedClusterView/Summary/ClusterStats/SubClusters';
import { TooltipWrapper } from '../../../../components/ToolTip/TooltipWrapper';
import { setParam } from '../../../../utils/urlParams';
import { RootState } from '../../../../store';
import { getRoom } from '../../../../utils/variables';
import { CompareButtons, CompareButtonType } from '../Utils/CompareButtons';

type ListViewRowProps = {
    setSelectedCluster: (id: string) => void
    cluster: Cluster
    selectedClusters?: SelectedClusters[]
    onSetSelectedClusters?: (values: SelectedClusters[]) => void
    noActionsAllowed?: boolean
    isCompareModal?: boolean
    compact?: boolean
}

const textLengthLimit = 300;
const expandedTextLimit = 999999;

export const ListViewRow = ({
    setSelectedCluster,
    cluster,
    onSetSelectedClusters,
    selectedClusters = [],
    noActionsAllowed,
    isCompareModal = false,
    compact
}: ListViewRowProps) => {
    const dispatch = useDispatch();
    const keywordsToHighlight = useQueryKeywords('clusterFilters');
    const [textLimit, setTextLimit] = useState(textLengthLimit);
    const checked = selectedClusters.map(x => x.id).indexOf(cluster.id) === -1;
    const { selectedCluster: selectedNarrativeTheme } = useSelector((state: RootState) => state.selectedCluster);
    const { updateClustersWatchlist } = useUpdateClustersWatchlist({
        clusterIds: [cluster.id],
        isWatching: !cluster.isWatching,
        type: 'cluster'
    });

    const { highlightedText: description } = addHighlights({
        text: cluster.description,
        keywordsToHighlight
    });

    const handleCheckboxChange = () => {
        if (!onSetSelectedClusters) return;
        if (!checked) {
            return onSetSelectedClusters(
                selectedClusters.filter(item => item.id !== cluster.id)
            );
        }

        return onSetSelectedClusters([
            ...selectedClusters,
            { id: cluster.id, isWatching: cluster.isWatching }
        ]);
    };
    const handleTextLimitChange = () => {
        setTextLimit(textLimit === expandedTextLimit ? textLengthLimit : expandedTextLimit);
    };
    const selectCluster = (hasDuplicatesFilter = false) => {
        setSelectedCluster(cluster.id);
        dispatch(saveSelectedCluster({
            selectedCluster: cluster.id,
            isParentCluster: true,
            hasDuplicatesFilter
        }));
    };
    const { highlightedText } = addHighlights({
        text: cluster.title,
        keywordsToHighlight
    });

    const room = getRoom();

    const clusterLink = `/situation-rooms/${room.id}/ai-narratives/narrative-theme/${cluster.id}`;

    const handleClick = (e: React.MouseEvent, tab?: string, hasDuplicates?: boolean) => {
        e.preventDefault();
        selectCluster(hasDuplicates);
        if (tab) {
            setParam('tab', tab);
        }
        if (hasDuplicates) {
            setParam('hasDuplicates', 'true');
        }
    };

    return (
        <tr key={cluster.id} className={(selectedNarrativeTheme === cluster.id) ? 'selected' : ''}>
            {(!noActionsAllowed && !compact && !isCompareModal) && (
                <td className="pr-0 pl-2 align-top">
                    <CustomInput type="checkbox"
                        checked={!checked}
                        id={`contentTableViewItem-${cluster.id}`}
                        data-testid="list-checkbox-input"
                        onChange={handleCheckboxChange}
                    />
                </td>
            )}
            <td className="p-2">
                <div id={`cluster-view-${cluster.id}`}>
                    <div className={`d-flex align-items-top justify-content-between ${compact ? '' : 'pb-2'}`}>
                        <p className="m-0 highlight-em mw-750 text-primary cursor-pointer highlight-em"
                            onClick={() => selectCluster()}
                        >
                            <Link to={clusterLink} onClick={handleClick} className="btn-link">
                                {getHighlightedContent(highlightedText)}
                            </Link>
                        </p>
                        {(!compact && !noActionsAllowed && !selectedClusters.length) && (
                            <div className="d-flex justify-content-end align-top content-top-bar">
                                <CompareButtons clusterIds={[cluster.id]}
                                    clusterTitles={[cluster.title]}
                                    type={CompareButtonType.AddRemoveButton}
                                    container={`cluster-view-${cluster.id}`}
                                    id={`cluster-view-${cluster.id}_comparison`}
                                    isCompareModal={isCompareModal}
                                    isParent
                                />
                                <TooltipWrapper tooltipText={cluster.isWatching ? 'Remove from watchlist' : 'Add to watchlist'}
                                    id={`cluster-view-${cluster.id}_watchlist`}
                                    placement="top-start"
                                    container={`cluster-view-${cluster.id}`}
                                >
                                    <Button color="link" className="p-0 mw-0 ml-1">
                                        <Star size={24}
                                            color="#006FF9"
                                            fill={cluster.isWatching ? '#006FF9' : 'white'}
                                            aria-label={cluster.isWatching ? 'Remove from watchlist' : 'Add to watchlist'}
                                            data-testid="watchlist-star"
                                            onClick={() => updateClustersWatchlist()}
                                        />
                                    </Button>
                                </TooltipWrapper>
                                <ActionDropdown id={cluster.id}
                                    defaultTitle={cluster.title}
                                    defaultSummary={cluster.description}
                                    containerId={`cluster-view-${cluster.id}`}
                                />
                            </div>
                        )}
                    </div>
                    {!compact && (
                        <Col lg={8} className="mw-750 px-0 pb-2">
                            <p className="highlight-em dont-break-out d-inline">
                                {getHighlightedContent(truncateString(description, textLimit))}&nbsp;
                            </p>
                            {(description.length > textLimit) && (
                                <Button color="link" className="p-0 mw-0" onClick={handleTextLimitChange}>Read more</Button>
                            )}
                            {(textLimit === expandedTextLimit) && (
                                <Button color="link" className="p-0 mw-0" onClick={handleTextLimitChange}>Read less</Button>
                            )}
                        </Col>
                    )}
                    {!compact && (
                        <div className="cluster-stats-align m-0">
                            <Engagement count={cluster.engagement} disabled />
                            <Link to={`${clusterLink}?tab=narrative-clusters`} onClick={(e) => handleClick(e, 'narrative-clusters')}>
                                <SubClusters count={cluster.subClustersMappedId.length}
                                    onClick={() => {
                                        // empty
                                    }}
                                />
                            </Link>
                            <Link to={`${clusterLink}?tab=content`} onClick={(e) => handleClick(e, 'content')}>
                                <Content count={cluster.volume}
                                    onClick={() => {
                                        // empty
                                    }}
                                />
                            </Link>
                            <Link to={`${clusterLink}?tab=content&hasDuplicates=true`} onClick={(e) => handleClick(e, 'content', true)}>
                                <Duplicates count={cluster.duplicatedDocsCount}
                                    onClick={() => {
                                        // empty
                                    }}
                                />
                            </Link>
                            <SentimentCard sentiments={cluster.sentimentJson}
                                averageSentiment={calculateAverageSentiment(cluster.sentimentJson)}
                                id={`sentiment_${cluster.id}`}
                            />
                        </div>
                    )}
                </div>
            </td>
        </tr>
    );
};
