import React from 'react';
import { useContent } from '../../../../../services/Content/getContent';
import { Loading } from '../../../../../components/Loading';
import { SourceIconLabel } from '../../../../../components/SourceIconLabel';
import { getPostedByName } from '../../../../../utils/getPostedByName';
import { getCtype, truncateString } from '../../../../../utils/text';
import { largeNumber } from '../../../../../utils/NumberFormat';
import { formatDate, formatDateLocal } from '../../../../../utils/getTimeFrom';
import { getRoom } from '../../../../../utils/variables';
import { TranslateButton } from '../../../../../components/Atoms/TranslateButton';
import { useTranslateMultipleText } from '../../../../../services/google/translate';

type OriginalContentProps = {
    originatorPostId: string
}

export const OriginalContent = ({ originatorPostId }: OriginalContentProps) => {
    const room = getRoom();
    const { loading, data: contentData } = useContent({
        filters: {
            ids: originatorPostId
        },
        name: 'getClusterContent'
    });
    const content = contentData && contentData[0];
    const ctype = getCtype(content?.contentType);
    const dateInLocalTimeZone = room.instance?.plan?.others?.dateInLocalTimeZone;
    const urlRegex = /(https?:\/\/[^ ]*)/;
    const fullText = content?.body?.length > 0 ? content?.body?.replace(urlRegex, '') : content?.text?.replace(urlRegex, '');
    const { getTranslation, data: translated, loading: translateLoading } = useTranslateMultipleText({
        text: fullText
    });
    const translatedText = translated.length ? translated[0].translations[0] : fullText;

    if (loading) {
        return <Loading relative height={200} background="white" />;
    }
    return (
        <div className="bg-white border p-2">
            <div className="mb-1 pr-1">
                <div className="d-flex align-items-top justify-content-between">
                    <div className="mr-1">
                        <span className="text-primary dont-break-out">{getPostedByName(content.from, content.displayName)} via </span>
                        <SourceIconLabel data={{ contentType: content?.contentType }} className="pl-1 d-inline" />
                        <span className="ctype">
                            {ctype.name}
                        </span>
                    </div>
                    <TranslateButton className="ml-1"
                        onClick={(e) => {
                            e.preventDefault();
                            getTranslation();
                        }}
                        lang={content.langVerbose}
                        detectedLangs={content.detectedLangs}
                        loading={translateLoading}
                    />
                </div>
                <p className="mt-2"><strong>Engagement: </strong>{content.engagement === -1 ? 'N/A' : largeNumber(content.engagement)}</p>
                <p className="mt-2">
                    {formatDate(content.pubdatetime, 'DD/MM/YYYY @ HH:mm:ss z')}
                    {dateInLocalTimeZone && (
                        ` | ${formatDateLocal(content.pubdatetime, 'DD/MM/YYYY @ HH:mm:ss z')}`
                    )}
                </p>
                <p className="mt-2">{truncateString(translatedText, 150)}</p>
                <a href={`/situation-rooms/${room.id}/matches/${content.id}`}
                    target="_blank"
                    rel="noreferrer"
                    className="btn btn-link p-0"
                >
                    View summary
                </a>
            </div>
        </div>
    );
};
