import React, { useState } from 'react';
import { Button } from 'reactstrap';
import { X } from 'react-feather';
import { useContent } from '../../../services/Content/getContent';
import { ContentTable, ContentTableTypes } from '../../../components/ContentTable';
import { CONTENT_TYPE } from '../../../utils/contentTypes';
import { Loading } from '../../../components/Loading';
import { NoDataFound } from './Nodatafound';
import { useModal } from '../../../context/modal/ModalComponent';
import { capitalizeFirstLetter } from '../../../utils/text';

type DuplicatedDocsModalProps = {
    contentIds: string[]
    title?: string
}

export const DuplicatedDocsModal = ({ contentIds, title }: DuplicatedDocsModalProps) => {
    const { closeModal } = useModal();
    const [offset, setOffset] = useState<number>(0);
    const limit = 10;
    const { loading, data: contents, count } = useContent({
        filters: {
            ids: contentIds
        },
        limit,
        skip: offset,
        fetchPolicy: 'no-cache',
        name: 'getClusterContents'
    });
    const headertitle = title === ('positive' || 'negative' || 'neutral') ? capitalizeFirstLetter(title)` sentiment` : title;
    return (
        <div>
            {contents.length > 0 && contents[0] !== null ? (
                <>
                    <h3 className="mb-3">
                        {title || 'Duplicate documents'}
                        <X className="float-right" type="button" onClick={closeModal} />
                    </h3>
                    <hr />
                    <p className="mb-2">{count} matches found for <strong>{headertitle}</strong></p>
                    <ContentTable contents={contents}
                        pagination={{ offset, setOffset, count }}
                        tableType={ContentTableTypes.MATCHES}
                        contentType={CONTENT_TYPE.MATCH}
                        total={count}
                        contentsToDisplay={limit}
                    />
                    <Button color="secondary" className="mt-2" onClick={closeModal}>Close</Button>
                </>
            ) : (
                <div className="bg-white p-4">
                    {loading ? <Loading relative height={300} /> : <NoDataFound />}
                </div>
            )}

        </div>
    );
};
