import React from 'react';
import { Star } from 'react-feather';
import { useDispatch } from 'react-redux';
import { TooltipWrapper } from '../../../../components/ToolTip/TooltipWrapper';
import { ActionDropdown } from '../Utils/ActionDropdown';
import { useUpdateClustersWatchlist } from '../../../../services/Clusters/watchlist';
import { ClusterWithSubClusters, SubCluster } from '../types';
import { resetSelectedCluster } from '../../store';
import { unsetParam } from '../../../../utils/urlParams';
import { CompareButtons, CompareButtonType } from '../Utils/CompareButtons';

type ActionButtonsProps = {
    clusterTitle: string
    summary: string
    isWatching?: boolean
    isSubCluster?: boolean
    cluster: ClusterWithSubClusters | SubCluster
    isParent?: boolean
    isCompareModal?: boolean
}

export const ActionButtons = ({ clusterTitle, summary, cluster, isWatching,
    isSubCluster = false, isParent = false, isCompareModal = false }: ActionButtonsProps) => {
    const dispatch = useDispatch();
    const { updateClustersWatchlist } = useUpdateClustersWatchlist({
        clusterIds: [cluster.id],
        isWatching: !cluster.isWatching,
        type: isSubCluster ? 'subcluster' : 'cluster'
    });
    const handleClusterRemoved = () => {
        unsetParam('narrative-theme');
        dispatch(resetSelectedCluster());
    };

    return (
        <div className="d-flex align-items-center justify-content-between">
            <CompareButtons clusterIds={[cluster?.id]}
                clusterTitles={[clusterTitle]}
                type={CompareButtonType.AddRemoveButton}
                isParent={isParent}
                isCompareModal={isCompareModal}
            />
            <TooltipWrapper tooltipText={isWatching ? 'Remove from watchlist' : 'Add to watchlist'}
                id="add_to_watchlist"
                placement="top-start"
                container="selected-cluster-view"
            >
                <Star size={24}
                    className="cursor-pointer"
                    color="#006FF9"
                    fill={cluster.isWatching ? '#006FF9' : 'white'}
                    aria-label={cluster.isWatching ? 'Remove from watchlist' : 'Add to watchlist'}
                    data-testid="watchlist-star"
                    onClick={() => updateClustersWatchlist()}
                />
            </TooltipWrapper>
            <ActionDropdown isSubCluster={!!isSubCluster}
                id={cluster.id}
                defaultTitle={clusterTitle}
                defaultSummary={summary}
                containerId="selected-cluster-view"
                onRemoved={handleClusterRemoved}
            />
        </div>
    );
};
