import { useQuery } from '@apollo/client';
import React, { useState } from 'react';
import { Button } from 'reactstrap';
import { X } from 'react-feather';
import { GET_ALL_SUB_CLUSTERS } from '../../../../../graphql';
import { Loading } from '../../../../../components/Loading';
import { getRoom } from '../../../../../utils/variables';
import { SubClustersTable } from '../../ListView/SubClustersTable';
import { SubCluster } from '../../types';
import { useModal } from '../../../../../context/modal/ModalComponent';

export const AddNarrativeModal = () => {
    const room = getRoom();
    const { closeModal } = useModal();
    const [subClustersOffset, setSubClustersOffset] = useState(0);
    const { data: subClustersData, loading: subClusterLoading } = useQuery(GET_ALL_SUB_CLUSTERS, {
        variables: {
            limit: 10,
            skip: subClustersOffset,
            projectId: room.project_id
        },
        fetchPolicy: 'cache-and-network'
    });
    const getSubClustersData = subClustersData?.getAllSubClusters;
    const subClusters: SubCluster[] = getSubClustersData?.subClusters || [];
    if (subClusterLoading) {
        return <Loading gif relative height={400} />;
    }
    return (
        <>
            <h2>
                Add narrative for comparison
                <X className="float-right" type="button" onClick={closeModal} />
            </h2>
            <hr />
            <SubClustersTable subClusters={subClusters}
                total={getSubClustersData.total}
                offset={subClustersOffset}
                onOffsetChange={setSubClustersOffset}
                itemsPerPage={10}
                isCompareModal
            />
            <Button color="secondary" className="mt-2" onClick={closeModal}>Close</Button>
        </>
    );
};
