import { gql, useLazyQuery, useQuery } from '@apollo/client';
import { useContentFilters } from '../../pages/Room/useFilters';

export const useContentLazy = ({
    filters: defaultFilters = {},
    limit = 10,
    skip = 0,
    sort,
    skipRequest = false,
    fetchPolicy,
    name = 'getContent',
    useGlobalFilters = false,
    fragments = defaultFragments
}) => {
    const filters = useContentFilters({ defaultFilters, useGlobalFilters });
    const [getContent, { data, loading }] = useLazyQuery(getContentSchema({ name, fragments }), {
        variables: {
            limit,
            offset: skip,
            sort,
            ...filters
        },
        skip: skipRequest,
        fetchPolicy
    });

    const content = data?.getContent?.content || [];
    const count = data?.getContent?.total || 0;

    return {
        getContent,
        data: content,
        count,
        loading
    };
};

export const useContent = ({
    filters: defaultFilters = {},
    limit = 10,
    skip = 0,
    sort = 'date',
    skipRequest = false,
    fetchPolicy = 'cache-first',
    name = 'getContent',
    useGlobalFilters = false,
    fragments = defaultFragments,
    watchlist = false
}) => {
    const filters = useContentFilters({ defaultFilters, useGlobalFilters, watchlist });
    const ret = useQuery(getContentSchema({ name, fragments }), {
        variables: {
            limit,
            offset: skip,
            sort,
            ...filters
        },
        skip: skipRequest,
        fetchPolicy
    });
    const content = ret.data?.getContent?.content || [];
    const count = ret.data?.getContent?.total || 0;

    return {
        ...ret,
        data: content,
        count
    };
};

const defaultFragments = ['Metadata', 'Document', 'Engagement', 'ThreatData', 'Annotations'];

export const getContentSchema = ({ name, fragments = defaultFragments }) => gql`
    query ${name}(
        $projectId: String!
        $sort: ContentSort
        $limit: Int = 10
        $offset: Int = 0
        $filters: ContentFilters
        $dslQuery: JSON
    ) {
        getContent(
            projectId: $projectId
            sort: $sort
            limit: $limit
            offset: $offset
            filters: $filters
            dslQuery: $dslQuery
        ) {
            total
            content {
                ${fragments.map(fragment => `...${fragment}`).join('\n')}
            }
        }
    }
    ${fragments.includes('Metadata') ? fragmentDefs.Metadata : ''}
    ${fragments.includes('Document') ? fragmentDefs.Document : ''}
    ${fragments.includes('Engagement') ? fragmentDefs.Engagement : ''}
    ${fragments.includes('ThreatData') ? fragmentDefs.ThreatData : ''}
    ${fragments.includes('Annotations') ? fragmentDefs.Annotations : ''}
`;

const fragmentDefs = {
    Metadata: gql`
        fragment Metadata on Content {
            id
            projectId
            contentType
            date
            datetime
            pubdatetime
        }
    `,
    Document: gql`
        fragment Document on Content {
            text
            body
            url
            from
            displayName
            uid
            image
            video
            threadId
            hashtags
            keywords
            extractedUrls
            mentions
        }
    `,
    Engagement: gql`
        fragment Engagement on Content {
            engagement
            likesCount
            repliesCount
            retweetsCount
            sharesCount
            viewsCount
        }
    `,
    ThreatData: gql`
        fragment ThreatData on Content {
            threatLevel
            threatObject {
                threatLevel
                threatLabels
                threatResolvers {
                    type
                    resolver
                    confidence
                }
            }
            whyathreat
        }
    `,
    Annotations: gql`
        fragment Annotations on Content {
            detectedLangs
            entities
            entitySentiment
            isThreat
            isToxic
            lang
            langVerbose
            locationsDetected
            sentiment
            sourceCredibility
        }
    `
};
