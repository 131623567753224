import React from 'react';
import { TooltipWrapper } from '../../../../../../components/ToolTip/TooltipWrapper';
import { truncateString } from '../../../../../../utils/text';
import { WidgetName, WidgetTextTooltip } from '../WidgetTextTooltip';

type NarrativeThemeProps = {
    title: string
    subClusterId: string
    clusterId: string
    onClick: ({ id }: { id: string }) => void
    disabled?: boolean
}

export const NarrativeTheme = ({
    title,
    subClusterId,
    clusterId,
    onClick,
    disabled
}: NarrativeThemeProps) => {
    const handleClick = () => {
        if (disabled) return;
        return onClick({ id: clusterId });
    };

    return (
        <div className="cluster-stat-container" onClick={handleClick}>
            <WidgetTextTooltip widgetName={WidgetName.NarrativeTheme} clusterId={subClusterId} />
            <div className="text-primary">
                <TooltipWrapper tooltipText={title} id={`tooltip-narrative-theme-${subClusterId}`}>
                    <span className="ctype cursor-pointer">
                        {truncateString(title, 20, false)}
                    </span>
                </TooltipWrapper>
            </div>
        </div>
    );
};
