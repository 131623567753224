import React, { useRef, useState, useMemo } from 'react';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { Loading } from '../../../../../components/Loading';
import { useModal } from '../../../../../context/modal/ModalComponent';
import { convertESInterval } from '../../../../../utils/convertESInterval';
import { getEndDate, getStartDate } from '../../../../../utils/getIntervalDateRange';
import { HandleDateRangeState } from './HandleDateRangeState';
import {
    storeExtremesAction,
    setRangeAction,
    selectExtremesMin,
    selectExtremesMax,
    resetMultiSmallChartsAction
} from '../../store';
import { differenceInMonths } from '../../../../../utils/getTimeFrom';
import { ContentPreviewModal } from '../../ContentModal/contentPreviewModal';
import { ContentModal } from '../../ContentModal/contentModal';
import { EmptyState } from '../../../../../components/EmptyState';
import { Images } from '../../../../../utils/modeSwitch';
import { useTotalThreatsAndMatches } from '../../../../../services/Overview';
import { CONTENT_TYPE } from '../../../../../utils/contentTypes';
import { color } from '../../../../../utils/getColors';
import { getRoom } from '../../../../../utils/variables';
import { saveFilters } from '../../../store';

const colors = [color.blue[500], color.graphColors.magenta];

Highcharts.SVGRenderer.prototype.symbols.circleHandle = () => [
    'M', -6, 40,
    'a', 5, -5, 0, 1, 1, 12, 0,
    'a', 5, 5, 0, 1, 1, -12, 0
];

export const FullLineChart = ({ items, preview, plotOptions }) => {
    const { setModal, closeModal } = useModal();
    const dispatch = useDispatch();
    const room = getRoom();
    const history = useHistory();
    const userMin = useSelector(selectExtremesMin);
    const userMax = useSelector(selectExtremesMax);
    const [defaultRange, setDefaultRange] = useState([]);
    const ref = useRef(null);
    const dateRef = useRef(null);
    const { data: totalMatchesAndThreatsOverTime, loading } = useTotalThreatsAndMatches({ showFull: true });
    const setInputRange = ({ rangeMin, rangeMax, range }) => {
        const newRange = range || [rangeMin, rangeMax].map(b => moment(new Date(b)).format('DD/MM/YYYY')).join(' - ');
        dispatch(setRangeAction(newRange));
        dateRef?.current?.setInputRange(newRange);
    };
    const chartRef = ref?.current?.chart;
    const buttonsConfig = useMemo(() => {
        if (chartRef && chartRef.axes[0]) {
            const max = moment(new Date(chartRef.axes[0].dataMax));
            const min = moment(new Date(chartRef.axes[0].dataMin));
            return differenceInMonths(min, max);
        }
        return ['1m', '3m', '6m', '1y', 'All'];
    }, [chartRef]);

    const getContentFilter = ({ chartPoint, date, end }) => ({
        date: {
            startDate: Math.floor(date / 1000),
            endDate: Math.floor(end / 1000)
        },
        ...(chartPoint.series.name === 'Threats' ? { isThreat: true } : {})
    });

    const getContentModal = ({ filter }) => {
        if (preview) {
            return <ContentPreviewModal filter={{ filter }} contentType={CONTENT_TYPE.THREAT} preview={preview} />;
        }

        return <ContentModal filter={{ filter }} contentType={CONTENT_TYPE.THREAT} />;
    };

    if (loading) {
        return <Loading height={500} relative />;
    }
    if (!totalMatchesAndThreatsOverTime?.matches) {
        return <EmptyState title="No data available" icon={Images().overview} />;
    }

    const interval = convertESInterval(totalMatchesAndThreatsOverTime.interval || '1d');
    const betaFiltersEnabled = !!room.instance?.plan?.others?.betaFilters;
    const handleOnClick = (point) => {
        const chart = ref?.current?.chart;
        const buckets = point.series.name === 'Matches'
            ? totalMatchesAndThreatsOverTime.matches.data : totalMatchesAndThreatsOverTime.threats.data;
        const date = point.category;
        const endDate = typeof (interval) === 'string' ? getEndDate(date, interval) : date + interval;
        const end = buckets[point.index + 1] ? buckets[point.index + 1].key - 1
            : endDate;
        // moment.utc().get('millisecond');
        // Store extremes to set when the modal re-renders
        if (chart) {
            const { min, max } = chart.xAxis[0];
            dispatch(storeExtremesAction({ userMin: min, userMax: max }));
        }
        if (betaFiltersEnabled) {
            const filters = getContentFilter({ chartPoint: point, date, end });
            dispatch(saveFilters({
                date: { startDate: filters.date.startDate, endDate: filters.date.endDate },
                dateRange: { startDate: filters.date.startDate, endDate: filters.date.endDate }
            }));
            return history.push({ pathname: `/situation-rooms/${room.id}/overview/matches`,
                state: { isThreat: filters?.isThreat || false,
                    appliedFilter: {
                        date: []
                    } } });
        }

        return setModal({
            header: `${point.series.name} - ${moment(new Date(date)).format('DD/MM/YYYY')}`,
            size: 'xl',
            goToPreviousOnClose: true,
            component: getContentModal({
                filter: getContentFilter({ chartPoint: point, date, end })
            })
        });
    };
    const options = {
        title: {
            text: ''
        },
        chart: {
            spacing: [10, 10, 3, 0],
            backgroundColor: 'transparent',
            zoomType: 'x',
            height: 510,
            plotBorderWidth: 1,
            plotBorderColor: color.grey[300],
            events: {
                load: (a) => {
                    const { xAxis } = a.target;
                    const { dataMax, dataMin } = xAxis[0];
                    const rangeMin = userMin || dataMin;
                    const rangeMax = userMax || dataMax;
                    // Restore the extremes from the store
                    if ((userMin && dataMin !== userMin) || (userMax && dataMax !== userMax)) {
                        xAxis.map(axis => axis.setExtremes(rangeMin, rangeMax));
                    }
                    setDefaultRange([rangeMin, rangeMax]);
                    setInputRange({ rangeMin, rangeMax });
                },
                render: () => {
                    const chart = document.getElementsByClassName('highcharts-stock-chart')[0];
                    const arrows = chart.getElementsByClassName('highcharts-scrollbar-arrow');
                    for (let i = 0; i < arrows.length; i++) {
                        const arrow = arrows[i];
                        if (i === 0) {
                            arrow.setAttribute('d', 'M9.22412 1.00024L1.00001 9.00024L9.22412 17.0002');
                            arrow.style.transform = 'translateX(-11px) translateY(2px)';
                        } else {
                            arrow.setAttribute('d', 'M1.03174 17.0002L9.25584 9.00025L1.03174 1.00024');
                            arrow.style.transform = 'translateX(12px) translateY(2px)';
                        }
                        arrow.setAttribute('stroke', color.blue[500]);
                        arrow.setAttribute('stroke-width', '2');
                    }
                    const legendSymbols = chart.querySelectorAll('.highcharts-legend-item .highcharts-graph');
                    for (const symbol of legendSymbols) {
                        symbol.outerHTML = `<rect width=${plotOptions ? '0' : '12'}
                                height=${plotOptions ? '0' : '12'}
                                rx="2"
                                style="transform: translateY(5px);"
                                fill="${symbol.getAttribute('stroke')}"
                            />`;
                    }
                }
            }
        },
        colors,
        series: items.map((item, i) => {
            const buckets = item.type === 'high_threats'
                ? totalMatchesAndThreatsOverTime.threats.data : totalMatchesAndThreatsOverTime.matches.data;
            return {
                name: plotOptions ? '' : item.title?.replace('Total ', ''),
                type: 'line',
                data: buckets.map(a => ({
                    x: a.key,
                    y: a.count
                })),
                marker: {
                    enabled: false,
                    fillColor: '#fff',
                    lineColor: colors[i],
                    lineWidth: 2,
                    radius: 2,
                    symbol: 'circle'
                },
                states: {
                    hover: {
                        halo: {
                            size: 5
                        }
                    }
                }
            };
        }),
        xAxis: {
            type: 'datetime',
            dateTimeLabelFormats: {
                day: {
                    main: '%d/%m/%Y'
                },
                month: {
                    main: '%m/%Y'
                },
                week: {
                    main: '%d/%m/%Y'
                },
            },
            gridLineColor: color.grey[300],
            gridLineWidth: 1,
            lineWidth: 1,
            lineColor: color.grey[300],
            events: {
                afterSetExtremes: (a) => {
                    const { userMin: min, userMax: max } = a;
                    setInputRange({
                        range: [min, max].map(b => moment(new Date(b)).format('DD/MM/YYYY')).join(' - ')
                    });
                }
            },
            labels: {
                style: {
                    color: color.grey[400]
                }
            }
        },
        yAxis: {
            gridLineColor: color.grey[300],
            gridLineWidth: 1,
            opposite: false,
            lineWidth: 1,
            lineColor: color.grey[300],
            labels: {
                style: {
                    color: color.grey[400]
                }
            }
        },
        tooltip: {
            shared: true,
            split: false,
            backgroundColor: '#fff',
            borderWidth: 0,
            padding: 0,
            headerFormat: `<div class="d-flex justify-content-between p-1">
                <span class="mr-4">Date</span>
                <span>{point.key}</span>
            </div><hr style="margin: 2px 0;" />`,
            pointFormatter() {
                const { series, y } = this;
                return `<div class="d-flex justify-content-between p-1">
                    <div class="d-flex align-items-center">
                        <div class="mr-1" style="background-color: ${series.color};
                        height: 12px; width: 12px; border-radius: 2px;"></div>
                        ${series.name}
                    </div>
                    <span>${y}</span>
                </div><hr class="m-0" />`;
            },
            xDateFormat: interval >= (24 * 60 * 60 * 1000) ? '%d/%m/%Y' : '%m/%Y %l:%M %p',
            style: {
                color: color.darkblue[700],
                fontFamily: 'inherit',
                fontSize: '14px'
            },
            useHTML: true
        },
        plotOptions: {
            series: {
                point: {
                    events: {
                        click: ({ point }) => {
                            handleOnClick(point);
                        }
                    }
                }
            }
        },
        navigator: {
            handles: {
                symbols: ['circleHandle', 'circleHandle'],
                borderColor: color.blue[500],
                backgroundColor: color.blue[500],
                width: 30
            },
            maskFill: 'rgba(0, 111, 249, 0.08)',
            series: items.map(() => ({
                type: 'line'
            })),
            xAxis: {
                dateTimeLabelFormats: {
                    day: {
                        main: '%d/%m/%Y'
                    },
                    month: {
                        main: '%m/%Y'
                    },
                    week: {
                        main: '%d/%m/%Y'
                    },
                },
            }
        },
        scrollbar: {
            buttonArrowColor: 'none',
            buttonBackgroundColor: '#fff',
            buttonBorderWidth: 0,
        },
        rangeSelector: {
            enabled: false
        },
        legend: {
            enabled: true,
            align: 'left',
            symbolWidth: 15,
            margin: 24,
            itemDistance: 16,
            padding: 0,
            itemStyle: {
                color: color.blue[500]
            },
            itemHoverStyle: {
                color: color.blue[600]
            }
        }
    };
    const toggleRange = (start, end) => {
        const chart = ref?.current?.chart;
        if (!chart) return false;
        let range = false;
        if (!end) {
            chart.xAxis.map(a => {
                range = [
                    a.dataMax - start < a.dataMin ? a.dataMin : a.dataMax - start,
                    a.dataMax
                ];
                a.setExtremes(...range);
            });
        } else {
            chart.xAxis.map(a => {
                range = [
                    start < a.dataMin ? a.dataMin : start,
                    end > a.dataMax ? a.dataMax : end
                ];
                a.setExtremes(...range);
            });
        }
        setInputRange({
            range: range.map(b => moment(new Date(b)).format('DD/MM/YYYY')).join(' - ')
        });
    };
    if (plotOptions) {
        options.plotOptions = plotOptions;
        return (
            <div className="highcharts-stock-chart">
                <div className="d-flex align-items-center flex-wrap">
                    <div className="mb-3">
                        <HandleDateRangeState toggleRange={toggleRange} ref={dateRef} />
                    </div>
                    <div className="d-flex align-items-center mb-3">
                        {buttonsConfig.map((item) => (
                            <Button className="mw-0 ml-2"
                                key={item}
                                onClick={() => toggleRange(
                                    defaultRange[1] - new Date(getStartDate(defaultRange[1], item)).getTime()
                                )}
                            >
                                {item}
                            </Button>
                        ))}
                    </div>
                </div>
                <HighchartsReact options={options}
                    highcharts={Highcharts}
                    constructorType="stockChart"
                    ref={ref}
                />
            </div>
        );
    }
    return (
        <div className="highcharts-stock-chart">
            <h2>Matches and threats over time</h2>
            <div className="d-flex align-items-center justify-content-end flex-wrap">
                <div className="mb-3">
                    <HandleDateRangeState toggleRange={toggleRange} ref={dateRef} />
                </div>
                { !preview
                && (
                    <div className="d-flex align-items-center mb-3">
                        {buttonsConfig.map((item) => (
                            <Button className="mw-0 ml-2"
                                key={item}
                                onClick={() => toggleRange(
                                    defaultRange[1] - new Date(getStartDate(defaultRange[1], item)).getTime()
                                )}
                            >
                                {item}
                            </Button>
                        ))}
                    </div>
                ) }
            </div>
            <HighchartsReact options={options}
                highcharts={Highcharts}
                constructorType="stockChart"
                ref={ref}
            />
            <Button className="mt-3"
                onClick={() => {
                    dispatch(resetMultiSmallChartsAction());
                    closeModal();
                }}
            >Close
            </Button>
        </div>
    );
};
