import { useReactiveVar } from '@apollo/client';
import moment from 'moment';
import React from 'react';
import { Button, Col, Row } from 'reactstrap';
import { Link } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import { capitalize } from 'lodash';
import { useModal } from '../../../../context/modal/ModalComponent';
import { DeleteArchiveModal } from '../Modals';
import { useUser } from '../../../../context/user/UserContext';
import { getRoom, refetchRoom } from '../../../../utils/variables';
import { BooleanInputValidated } from '../../../../components/Molecules';
import { TooltipWrapper } from '../../../../components/ToolTip/TooltipWrapper';

export const Details = () => {
    const room = getRoom();
    const { isAdmin } = useUser();
    const { setModal, closeModal } = useModal();
    const refetch = useReactiveVar(refetchRoom);
    const handleModal = (type) => {
        const modalHeader = `${capitalize(type)} situation room`;
        setModal({
            header: modalHeader,
            component: <DeleteArchiveModal type={type}
                data={{ id: room.id,
                    title: room.project_name,
                    draftId: room.draftId,
                    situationRoomId: room.situationRoomId || room.id }}
                closeModal={closeModal}
                onDone={refetch}
            />
        });
    };
    return (
        <div className="bg-white p-3 border rounded">
            <h3 className="mb-2 mt-0">Room details</h3>
            <p className="mb-3">View details and manage the situation room here</p>
            <Row className="mx-n3  bg-verylight">
                <Col xs={6} className="border py-3 px-3">
                    <h3 className="m-0">Details</h3>
                </Col>
                <Col xs={6} className="border py-3 px-3">
                    <h3 className="m-0">{room.query_type === 'boolean' ? 'Boolean' : 'Keywords'}</h3>
                </Col>
            </Row>
            <Row className="mx-n3">
                <Col xs={6} className="border p-3 pb-0">
                    <div className="mb-3">
                        <p className="m-0"><strong>Situation room name</strong></p>
                        <p>{room.project_name}</p>
                    </div>
                    <div className="mb-3">
                        <TooltipWrapper id="tooltip-settings-date"
                            placement="top"
                            tooltipText="Content is being monitored from this
                            date onward. You can update this if needed."
                        >
                            <p className="font-weight-bold widget-text-style set-fit-content">Monitoring start date</p>
                        </TooltipWrapper>
                        <p>{moment(room.start_date).format('DD/MM/YYYY')}</p>
                    </div>
                    <div className="mb-3">
                        <TooltipWrapper id="tooltip-settings-restrictedVisibility"
                            placement="top"
                            tooltipText="Data is visible for the past (30)
                                days as per your selection during setup.
                                This setting cannot be changed."
                        >
                            <p className="font-weight-bold widget-text-style set-fit-content">Restricted visibility</p>
                        </TooltipWrapper>
                        <p>{room.contentVisibilityRestriction || 'NA'}</p>
                    </div>
                    <div className="mb-3">
                        <TooltipWrapper id="tooltip-settings-roomDescription"
                            placement="top"
                            tooltipText="This outlines the main objective or
                            goal you set when creating this dashboard."
                        >
                            <p className="font-weight-bold widget-text-style set-fit-content">Situation room description</p>
                        </TooltipWrapper>
                        <p>{room.reason || 'NA'}</p>
                    </div>
                    <div className="mb-3">
                        <p className="m-0"><strong>Created</strong></p>
                        <p>{moment(room.createdAt).format('DD/MM/YYYY | hh:mm A')} by {room
                            ?.createdBy?.displayName}
                        </p>
                    </div>
                    <div className="m-0">
                        <p className="m-0"><strong>Last updated</strong></p>
                        <p className="m-0">
                            {room.updatedAt && room.updatedBy
                                ? `${moment(room.updatedAt).format('DD/MM/YYYY | hh:mm A')} by ${room?.updatedBy?.displayName}`
                                : 'Not updated' }
                        </p>
                    </div>
                </Col>
                <Col xs={6} className="border settings">
                    {room.query_type === 'boolean'
                        ? (
                            <BooleanInputValidated booleanQuery={room.query?.boolean}
                                setBooleanQuery=""
                                setValidationErrors=""
                                validationErrors=""
                                disabled
                                settings
                            />
                        )
                        : (
                            <><p className="m-0 pt-3 pb-2"><strong>Included keywords</strong></p>
                                <div className="d-flex align-items-start flex-wrap">
                                    {[...room.query.included].map((word) =>
                                        <div key={uuid()} className="mr-1 tag text-truncate settings-tag">{word.value}</div>)}
                                </div>
                                <p className="m-0 pt-3 pb-2"><strong>Excluded keywords</strong></p>
                                <div className="d-flex align-items-start flex-wrap mb-2">
                                    {[...room.query.excluded].map((word) =>
                                        <div key={uuid()} className="mr-1 tag mb-1 text-truncate settings-tag">{word.value}</div>)}
                                </div>
                            </>
                        )}
                </Col>
            </Row>
            {isAdmin && (
                <>
                    <p className="my-3">
                        Room archiving can be reversed. Room deletion cannot be reversed.
                    </p>
                    <Link to={`/situation-room/edit-room/${room.id}`}>
                        <Button color="primary" className="mr-2">Edit room</Button>
                    </Link>
                    <Button color="secondary" className="mr-2" onClick={() => handleModal('archive')}>Archive room</Button>
                    <Button color="danger" onClick={() => handleModal('delete')}>Delete room</Button>
                </>
            )}
        </div>
    );
};
