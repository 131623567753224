import React from 'react';
import * as FeatherIcon from 'react-feather';
import { Link } from 'react-router-dom';
import { SearchSortDropdown } from '../../../../components/Search/SearchSortDropdown';
import { TooltipItem } from '../../../../components/ToolTip';
import { getRoom } from '../../../../utils/variables';
import { color } from '../../../../utils/getColors';

export const MatchesHeader = ({ matches, count, sort, setSort }) => {
    const room = getRoom();
    const handleSort = (sortNum, e) => {
        const sortOptions = [
            'date',
            'engagement',
            'score'
        ];
        setSort({ value: sortOptions[sortNum - 1], name: e.target.name });
    };
    return (
        <div className="mb-2 bg-white">
            <div className="d-flex justify-content-between">
                <div className="d-flex align-items-center">
                    <h3 className="pr-1">Matches</h3>
                    <TooltipItem id="matchesFeed"
                        placement="top"
                        content="New content matches will appear in your feed chronologically as they are detected and analysed."
                    />
                </div>
                <div className="d-flex align-items-center">
                    <Link to={`/situation-rooms/${room.id}/overview/matches`}
                        className="d-flex align-items-center"
                        data-testid="matches-live-feed-see-more"
                    >
                        <p className="m-0 text-primary">See more</p>
                        <FeatherIcon.ChevronRight color={color.blue[500]} size={18} />
                    </Link>
                </div>
            </div>
            <hr className="mx-n3" />
            {count ? (
                <>
                    <div className="d-flex align-items-center justify-content-between flex-wrap">
                        <p className="my-0 mr-2">
                            Showing {matches.length}/{count} matches
                        </p>
                        <div className="mr-n2 mb-n1">
                            <SearchSortDropdown score sortOption={sort} handleSort={handleSort} />
                        </div>
                    </div>
                    <hr className="mx-n3" />
                </>
            ) : null}
        </div>
    );
};
