import React from 'react';
import { Maximize2, TrendingDown, TrendingUp } from 'react-feather';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { MatchesWidget } from '../../../../../components/Highcharts';
import { useModal } from '../../../../../context/modal/ModalComponent';
import { largeNumber } from '../../../../../utils/NumberFormat';
import { trendline } from '../../../../../utils/trendline';
import { TitleTooltip } from '../utils';
import { FullLineChart } from './FullLineChart';
import { LoadingState } from './Loading';
import { resetMultiSmallChartsAction } from '../../store';
import { getEndDate, getIntervalDateRange } from '../../../../../utils/getIntervalDateRange';
import { ContentPreviewModal } from '../../ContentModal/contentPreviewModal';
import { ContentModal } from '../../ContentModal/contentModal';
import { useTotalThreatsAndMatches } from '../../../../../services/Overview';
import { CONTENT_TYPE } from '../../../../../utils/contentTypes';
import { color } from '../../../../../utils/getColors';
import { getRoom } from '../../../../../utils/variables';
import { saveFilters } from '../../../store';

export const TotalMatchesAndThreatsOverTime = ({ items, preview }) => {
    const room = getRoom();
    const dispatch = useDispatch();
    const history = useHistory();
    const { setModal, closeModal } = useModal();
    const { data: totalMatchesAndThreatsOverTime, loading } = useTotalThreatsAndMatches({ showFull: false });

    const getContentFilter = ({ endDate, date, item }) => ({
        date: {
            startDate: Math.floor(date / 1000),
            endDate: Math.floor(endDate / 1000)
        },
        ...(item.title === 'Total Threats' ? { isThreat: true } : {})
    });

    const getContentModal = ({ filter }) => {
        if (preview) {
            return <ContentPreviewModal filter={{ filter }} contentType={CONTENT_TYPE.THREAT} preview={preview} />;
        }

        return <ContentModal filter={{ filter }} contentType={CONTENT_TYPE.THREAT} />;
    };

    if (loading) {
        return <LoadingState items={items} />;
    }
    if (!totalMatchesAndThreatsOverTime.matches) {
        return null;
    }
    const betaFiltersEnabled = !!room.instance?.plan?.others?.betaFilters;
    const handleDirectMatchesPage = ({ filter }) => {
        const { date } = filter;
        dispatch(saveFilters({
            date,
            dateRange: date
        }));
        history.push({ pathname: `/situation-rooms/${room.id}/overview/matches`,
            state: { isThreat: filter?.isThreat || false,
                appliedFilter: {
                    date: []
                } } });
    };
    const handleOnClick = (e, interval, current, item) => {
        const start = current.data[e.point.index].key;
        const end = current.data[e.point.index + 1]
            ? current.data[e.point.index + 1].key - 1
            : getEndDate(start, interval);
            // moment.utc().get('millisecond');
        betaFiltersEnabled
            ? handleDirectMatchesPage({ filter: getContentFilter({ endDate: end, date: start, item }) })
            : setModal({
                header: `${item.title} - ${getIntervalDateRange(start, interval)}`,
                size: 'xl',
                goToPreviousOnClose: true,
                component: getContentModal({
                    filter: getContentFilter({
                        endDate: end,
                        date: start,
                        item
                    })
                })
            });
    };

    return (
        <div data-testid="multi-small-charts">
            {items.map((item, index) => {
                const current = item.type === 'high_threats'
                    ? totalMatchesAndThreatsOverTime.threats : totalMatchesAndThreatsOverTime.matches;
                const values = current.data.map(a => a.count);
                const count = largeNumber(current.count);
                const trend = getTrend(values);
                const { interval } = totalMatchesAndThreatsOverTime;
                return (
                    <div key={item.id} className={index !== items.length - 1 ? 'mb-4' : ''}>
                        <div className="d-flex align-items-center mb-2">
                            <TitleTooltip title={item.title} type={item.type} />
                            <h3 className="my-0 ml-3">
                                {count}
                                {trend && (
                                    <span className="ml-11">
                                        {trend.textClass === 'text-warning'
                                            ? <TrendingUp color={color.green[200]} size={20} />
                                            : <TrendingDown color={color.red[200]} size={20} />}
                                    </span>
                                )}
                            </h3>
                        </div>
                        <MatchesWidget colors={[item.type === 'high_threats' ? color.graphColors.magenta : color.blue[500]]}
                            data={values}
                            height={100}
                            width={null}
                            dates={current.data.map(a => a.key)}
                            interval={interval}
                            onClick={(e) => {
                                handleOnClick(e, interval, current, item);
                            }}
                        />
                    </div>
                );
            })}
            <div className="absolute-bottom-right p-1"
                role="button"
                data-testid="multi-small-charts-maximize-button"
                onClick={() => setModal({
                    header: false,
                    size: 'xl',
                    component: <FullLineChart items={items} preview={preview} />,
                    onClose: () => {
                        dispatch(resetMultiSmallChartsAction());
                        closeModal();
                    }
                })}
            >
                <Maximize2 className="text-primary" role="button" />
            </div>
        </div>
    );
};

const getTrend = (values) => {
    const { slope } = trendline(values.map((y, x) => ({ x, y })));
    if (slope > 0) {
        return {
            textClass: 'text-warning',
            icon: 'uil uil-arrow-up'
        };
    }
    return {
        textClass: 'text-success',
        icon: 'uil uil-arrow-down'
    };
};
