import React from 'react';
import { useMutation } from '@apollo/client';
import { Button } from 'reactstrap';
import { REMOVE_CLUSTER_SUBCLUSTER } from '../../../../../graphql';
import { getRoom } from '../../../../../utils/variables';
import { useModal } from '../../../../../context/modal/ModalComponent';
import { getToast } from '../../../../../utils/getToast';

type RemoveConfirmationProps = {
    id: string
    isParentCluster: boolean
    onRemoved?: () => void
}

export const RemoveConfirmation = ({ id, isParentCluster, onRemoved }: RemoveConfirmationProps) => {
    const { closeModal } = useModal();
    const room = getRoom();
    const toast = getToast();
    const [removeCluster, { loading }] = useMutation(REMOVE_CLUSTER_SUBCLUSTER, {
        refetchQueries: 'active',
        onCompleted: () => {
            if (onRemoved) onRemoved();
            closeModal();
            toast.success(`${isParentCluster ? 'Narrative theme' : 'Narrative'} removed successfully`);
        },
        onError: () => {
            closeModal();
            toast.error(`Failed to remove ${isParentCluster ? 'narrative theme' : 'narrative'}`);
        }
    });
    const onRemoveCluster = () => removeCluster({
        variables: {
            projectId: room.project_id,
            clusterId: isParentCluster ? id : null,
            subClusterId: !isParentCluster ? id : null,
        }
    });

    return (
        <>
            <h3>Are you sure that you want to remove this {isParentCluster ? 'narrative theme'
                : 'narrative'}?
            </h3>
            <hr />
            <p>The selected {isParentCluster ? 'narrative theme ' : 'narrative '}
                will be fully removed from this situation room.
            </p>
            <hr />
            <Button color="secondary" className="mr-2" onClick={closeModal}>Cancel</Button>
            <Button disabled={loading} color="danger" onClick={onRemoveCluster}>Remove</Button>
        </>
    );
};
