import React from 'react';
import { ExternalLink } from 'react-feather';
import { Button } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { ContentPreview } from '../../../../components/ContentPreview';
import { SourceIconLabel } from '../../../../components/SourceIconLabel';
import { SentimentPanel } from './SentimentPanel';
import { color } from '../../../../utils/getColors';
import { ExternalLinkButton } from '../../../../components/LinkModal/ExternalLinkButton';
import { TextTooltip } from '../../../../components/ToolTip/TextTooltip';
import { Content } from '../../../../services/Content/contentInterface';
import { RemoveContentModal } from '../../../../components/CommonModals/RemoveContent';
import { useModal } from '../../../../context/modal/ModalComponent';
import { CONTENT_TYPE } from '../../../../utils/contentTypes';
import { getRoom } from '../../../../utils/variables';

type RightPanelProps = {
    content: Content
}

export const RightPanel = ({ content }: RightPanelProps) => {
    const room = getRoom();
    const history = useHistory();
    const { setModal } = useModal();
    const contentType = content?.threatObject?.threatLevel !== 'none' ? CONTENT_TYPE.THREAT : CONTENT_TYPE.MATCH;
    const handleOnContentRemove = () => {
        if (contentType === CONTENT_TYPE.THREAT) {
            return history.push(`/situation-rooms/${room.id}/threats`);
        }

        return history.push(`/situation-rooms/${room.id}/overview/matches`);
    };

    const setRemoveContentModal = () => {
        setModal({
            modal: <RemoveContentModal removeData={[{ id: content.id }]} type={contentType} onCompleted={handleOnContentRemove} />,
            goToPreviousOnClose: true
        });
    };
    return (
        <div className="right-bar">
            <div className="bg-white mb-3 p-2 rounded-sm">
                <h3 className="mt-0 mb-1 d-flex justify-content-between">
                    Content preview
                    {content.url && (
                        <TextTooltip placement="top"
                            title="Open source"
                            id={`content-${content.id}-link`}
                            withIcon
                        >
                            <ExternalLinkButton url={content.url}>
                                <ExternalLink size={24} color={color.blue[500]} />
                            </ExternalLinkButton>
                        </TextTooltip>
                    )}
                </h3>
                <ContentPreview data={content} />
            </div>
            <SentimentPanel sentiment={content.sentiment} />
            <div className="bg-white mb-3 p-2 rounded-sm">
                <h3 className="m-0">Actor</h3>
                <div className="bg-verylight p-2 mt-1">
                    <SourceIconLabel data={content} link />
                </div>
            </div>
            <div className="bg-white mb-3 p-2 rounded-lg">
                <Button color="danger" onClick={setRemoveContentModal}>Remove content</Button>
            </div>
        </div>
    ); };
